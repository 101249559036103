import { 
    defineComponent, 
    reactive, 
    getCurrentInstance, 
    ComponentInternalInstance 
} from 'vue'
import { setPassword } from '@/api/index'
import { useRouter } from 'vue-router'

import './index.less'

export default defineComponent({
    name: 'Pwd',
    setup() {
        const { appContext } = getCurrentInstance() as ComponentInternalInstance
        const proxy = appContext.config.globalProperties

        const router = useRouter()

        const state = reactive({
            oldPassword: '',
            newPassword: '',
            newPassword2: ''
        })
        const onSubmit = async (values: any) => {
            if (values.newPassword != values.newPassword2) {
               return proxy.$toast('两次确认密码不一致') 
            }
            delete values.newPassword2 
            const res: any = await setPassword(values)
            if (res.code === 0) {
                proxy.$toast('密码成功!')
                state.oldPassword = ''
                state.newPassword = ''
                state.newPassword2 = ''
                return 
            } else {
                proxy.$toast(res.message)
            }
        }

        const onBack = () => {
            state.oldPassword = ''
            state.newPassword = ''
            state.newPassword2 = ''
            router.back()
        }

        const checkPwdOld = (value: string) => {
            if (!value) return '密码不能为空!'
            return true
        }

        const checkPwd = (value: string) => {
            if (!value) return '新密码不能为空!'
            const patrn = /^[0-9A-Za-z]{8,}$/
            return patrn.test(value) ? true : '请输入8位字母+数字组合'
        }

        const checkPwd2 = (value: string) => {
            const newPassword = state.newPassword
            if (!value) return '确认密码不能为空!'
            if (value && newPassword !== value) '两次确认密码不一致'
            const patrn = /^[0-9A-Za-z]{8,}$/
            return patrn.test(value) ? true : '请输入8位字母+数字组合'
        }

        return () => (
            <div class='pwd-page'>
                 <div class="header-box flex-box justify-content-between plr35">
                    <div>修改密码</div>
                    <img class="logo" src="https://img.fanwoon.com/new_logo.png" />
                </div>
                <van-form onSubmit={onSubmit}>
                    <div class="form-list">
                        <div class="form-list-item">
                            <div class="label-font"><label class="star">*</label>原始密码</div>
                            <div class="flex-box align-items-center gender-box">
                                 <van-field 
                                        v-model={ state.oldPassword }
                                        name="oldPassword" 
                                        class="input-box" 
                                        type="password"
                                        placeholder="请输入您当前的密码"
                                        rules={[{ validator: checkPwdOld }]}
                                         />
                            </div>
                        </div>
                        <div class="form-list-item">
                            <div class="label-font"><label class="star">*</label>新密码</div>
                            <div class="flex-box align-items-center gender-box">
                                 <van-field 
                                        v-model={ state.newPassword }
                                        name="newPassword" 
                                        class="input-box" 
                                        type="password"
                                        placeholder="8位以上字母 + 数字组合"
                                        rules={[{ validator: checkPwd }]}
                                         />
                            </div>
                        </div>
                        <div class="form-list-item">
                            <div class="label-font"><label class="star">*</label>确认密码</div>
                            <div class="flex-box align-items-center gender-box">
                                 <van-field 
                                        v-model={ state.newPassword2 }
                                        name="newPassword2" 
                                        class="input-box" 
                                        type="password"
                                        placeholder="请再输入一次新密码"
                                        rules={[{ validator: checkPwd2 }]}
                                         />
                            </div>
                        </div>
                        <div class="footer-box">
                            <div class="container-box">
                                <div>
                                <van-button class="cancel-btn" type="info" onClick={ onBack }>取消</van-button>
                                </div>
                                <div>
                                    <van-button class="sub-btn" type="info" native-type="submit">提交</van-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </van-form>
            </div>
        )
    }
})